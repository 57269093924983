<template>
  <div style="margin-top: 80px;">
    <el-row>
      <div class="container">
        <el-card style="margin-bottom: 30px" class="box-card">
          <el-container>
            <el-header>
              <div class="condition-content-list-name dis-cen-cen">
                <div class="dis-sta-cen"><span style="font-size: 24px;color: #333333;">{{dataTable.title || '-'}}</span></div>
                <span>
                <span class="focus-enterprises-tag-black">{{dataTable.kindcodedesc || '-'}}</span>
              </span>
              </div>
            </el-header>
            <el-main>
              <div class="achievement-content-title">
                <div class="achievement-title-remind">专利详情</div>
                <div>
                  <el-descriptions class="margin-top" :column="2" :size="size" border>
                    <el-descriptions-item :span="1">
                      <template #label>申请号</template>
                      {{dataTable.applicationnumber || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item :span="1">
                      <template #label>申请日</template>
                      {{dataTable.applicationdate ? (dataTable.applicationdate).substring(0, 10) : '' || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item :span="1">
                      <template #label>公开（公告）号</template>
                     {{dataTable.publicationnumber || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item :span="1">
                      <template #label>公开（公告）日期</template>
                      {{dataTable.publicationdate ? (dataTable.publicationdate).substring(0, 10) : '' || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item :span="1">
                      <template #label>发明人</template>
                      {{dataTable.inventorstringlist || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item :span="1">
                      <template #label>申请人</template>
                      {{dataTable.inventorstringlist || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item :span="1">
                      <template #label>代理机构</template>
                      {{dataTable.agency || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item :span="1">
                      <template #label>代理人</template>
                      {{dataTable.agent || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item :span="1">
                      <template #label>IPC分类号</template>
                      {{dataTable.ipclist || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item :span="1">
                      <template #label>分类</template>
                      {{dataTable.ipcdesc || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item :span="1">
                      <template #label>法律状态详情</template>
                      {{dataTable.legalstatusdesc || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item :span="1">
                      <template #label>法律状态日期</template>
                      {{dataTable.legalstatusdate ? (dataTable.legalstatusdate).substring(0, 10) : '' || '-'}}
                    </el-descriptions-item>
                    <!-- <el-descriptions-item :span="2">
                      <template #label>法律历史状态</template>
                      <el-timeline v-if="dataTable.patentlegalhistory">
                        <el-timeline-item
                          v-for="(activity, index) in dataTable.patentlegalhistory"
                          :key="index"
                          color="#5D6FE9"
                          :timestamp="activity.content">
                          {{activity.timestamp}}
                        </el-timeline-item>
                      </el-timeline>
                      <span v-else>'-'</span>
                    </el-descriptions-item> -->
                    <el-descriptions-item :span="2">
                      <template #label>摘要</template>
                      {{dataTable.abstracts || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item :span="2">
                      <template #label>专利图片</template>
                      <el-image :src="dataTable.patentimage ? dataTable.patentimage : ''"  fit="contain"></el-image>
                    </el-descriptions-item>
                  </el-descriptions>
                </div>
              </div>
            </el-main>
          </el-container>
        </el-card>
      </div>
    </el-row>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import { forwardBG } from '@/api/forward'
export default {
  name: 'company-patent-details',
  data () {
    return {
      dataTable: {},
      url: require('../../assets/index/g.png'),
      activities: [{
        content: '商标注册申请---等待驳回通知发文---结束',
        timestamp: '2017-03-22'
      }, {
        content: '商标注册申请---等待受理通知书发文---结束',
        timestamp: '2016-11-04'
      }, {
        content: '商标注册申请---申请收文---结束',
        timestamp: '2016-05-27'
      }]
    }
  },
  methods: {
    init () {
      const vm = this
      if (!vm.item.id) {
        return ElMessage.warning({
          message: '未获取到id',
          type: 'warning',
          offset: 60
        })
      }
      const data = {
        dateBackgroundUrl: '/companyIntellectualProperty/getPatentDetail',
        dateBackgroundRequestType: 'get',
        data: {
          id: vm.item.id
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const data = result.data ? result.data : {}
          console.log(data)
          if (data.patentlegalhistory && data.patentlegalhistory.indexOf('[{') >= 0) {
            const activities = JSON.parse(data.patentlegalhistory)
            const arr = []
            activities.forEach(item => {
              const obj = {
                content: item.status,
                timestamp: item.date
              }
              arr.push(obj)
            })
            data.patentlegalhistory = arr
          } else {
            data.patentlegalhistory = []
          }
          vm.dataTable = data
          console.log(vm.dataTable)
        } else {
          return ElMessage.error(result.msg)
        }
      }).catch(error => {
        console.log(error)
      })
    }
  },
  mounted () {
    const vm = this
    const itemStr = sessionStorage.getItem('company-intellectual-details-item')
    if (itemStr) {
      const item = JSON.parse(itemStr)
      vm.item = item
    }
    vm.init()
  }
}
</script>

<style scoped="scoped">
.focus-enterprises-tag-black {
  color: black;
  padding: 0 5px;
  border: 1px solid black;
  cursor: pointer;
  font-size: 12px;
  margin-left: 10px;
}
.achievement-title-remind {
  margin-bottom: 20px;
  font-size: 18px;
  text-align: left;
  color: #333333;
}
/deep/ .el-descriptions :not(.is-bordered) td, .el-descriptions :not(.is-bordered) th {
  max-width: 20px;
}
</style>
